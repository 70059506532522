import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    IconButton,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { DroppedItemInfo, ReorderModalProps } from '../../types/form-builder';

const ReorderModal: React.FC<ReorderModalProps> = ({ open, onClose, items, onReorder }) => {
    const [localItems, setLocalItems] = useState<DroppedItemInfo[]>([]);

    useEffect(() => {
        setLocalItems([...items]);
    }, [items]);

    const handleMoveItem = (index: number, direction: 'up' | 'down') => {
        const newItems = [...localItems];
        if (direction === 'up' && index > 0) {
            [newItems[index], newItems[index - 1]] = [newItems[index - 1], newItems[index]];
        } else if (direction === 'down' && index < newItems.length - 1) {
            [newItems[index], newItems[index + 1]] = [newItems[index + 1], newItems[index]];
        }
        setLocalItems(newItems);
    };

    const handleSave = () => {
        onReorder(localItems);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Reorder Items</DialogTitle>
            <DialogContent>
                <List>
                    {localItems.map((item, index) => (
                        <ListItem key={item.id}>
                            <ListItemText primary={item.name} />
                            <IconButton
                                onClick={() => handleMoveItem(index, 'up')}
                                disabled={index === 0}
                            >
                                <ArrowUpwardIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => handleMoveItem(index, 'down')}
                                disabled={index === localItems.length - 1}
                            >
                                <ArrowDownwardIcon />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReorderModal;
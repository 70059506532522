import {
    Box,
    Drawer,
    SvgIcon,
    useMediaQuery
} from '@mui/material';

import Logo from "./Logo"
import { useLocation } from 'react-router-dom';
import { Theme } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import PeopleIcon from '@mui/icons-material/People';
import SecurityIcon from '@mui/icons-material/Security';

import { SIDE_NAV_WIDTH } from './consts';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useEffect, useState } from 'react';
import { PageEnum, RoleEnum } from '../../types/enums';
import { IUserPageAccess } from '../../types/manage';
import { Build } from '@mui/icons-material';

export interface IPage {
    title: string;
    path: string;
    pageCode: string;
    icon: React.JSX.Element;
}

export const SideNav = (props) => {
    const { open, onClose } = props;

    const user = useSelector((state: RootState) => state.userDetail);

    const navigate = useNavigate();

    const location = useLocation();
    const pathname = location.pathname;

    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    const onMenuItemClicked = (route: string) => {
        onClose();
        navigate(route);
    }

    const [items, setItems] = useState<IPage[]>([
        {
            title: 'My Forms',
            path: '/form-list',
            pageCode: PageEnum.MyForms,
            icon: (
                <SvgIcon fontSize="small">
                    <CorporateFareIcon />
                </SvgIcon>
            )
        },
        {
            title: 'Form Builder',
            path: '/form-builder',
            pageCode: PageEnum.FormBuilder,
            icon: (
                <SvgIcon fontSize="small">
                    <RoomPreferencesIcon />
                </SvgIcon>
            )
        },
        {
            title: 'Form Builder (New)',
            path: '/form-builder-new',
            pageCode: PageEnum.FormBuilderNew,
            icon: (
                <SvgIcon fontSize="small">
                    <Build />
                </SvgIcon>
            )
        },
        {
            title: 'Users',
            path: '/users',
            pageCode: PageEnum.Users,
            icon: (
                <SvgIcon fontSize="small">
                    <PeopleIcon />
                </SvgIcon>
            )
        },
        {
            title: 'Customers',
            path: '/customers',
            pageCode: PageEnum.Customers,
            icon: (
                <SvgIcon fontSize="small">
                    <BusinessIcon />
                </SvgIcon>
            )
        },
        {
            title: 'Form Groups',
            path: '/form-groups',
            pageCode: PageEnum.FormGroups,
            icon: (
                <SvgIcon fontSize="small">
                    <CorporateFareIcon />
                </SvgIcon>
            )
        },
        {
            title: 'Roles Management',
            path: '/roles',
            pageCode: PageEnum.RoleManagement,
            icon: (
                <SvgIcon fontSize="small">
                    <SecurityIcon />
                </SvgIcon>
            )
        }
    ]);
    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            <Logo />
            {user.isActive && <List component="nav">
                {items.map((item) => {

                    return (
                        <ListItem onClick={() => onMenuItemClicked(item.path)} key={item.path} disablePadding sx={{ display: 'block' }} >
                            <ListItemButton
                                selected={pathname === item.path}
                                sx={{
                                    minHeight: 48,
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: 2,
                                        justifyContent: 'center',
                                    }}
                                >
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText className='menu-text' primary={item.title} />
                                {/* <span>{pathname} === {item.path}</span> */}
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>}
        </Box>
    );

    // Perform any setup that requires access to the DOM
    useEffect(() => {

        let pagesToShow: IPage[] = [];

        if (user.pageAccess) {
            user.pageAccess.forEach((pageFromServer: IUserPageAccess) => {
                if (pageFromServer.canView === true) {
                    let matchedPage = items.find(item => item.pageCode === pageFromServer.pageCode);
                    if (matchedPage) {
                        pagesToShow.push(matchedPage);
                    }
                }
            });
        }

        setItems(pagesToShow)

    }, [user]);

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                className='side-menu-drawer'
                onClose={onClose}
                open={open}
                PaperProps={{
                    sx: {
                        width: SIDE_NAV_WIDTH
                    }
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            className='side-menu-drawer'
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    width: SIDE_NAV_WIDTH
                }
            }}
            // sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary"
        >
            {content}
        </Drawer>
    );
};

import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ReorderModal from './ReorderModal';
import DeleteModal from './DeleteModal';
import { useDroppable } from '@dnd-kit/core';
import { DroppedItemInfo } from '../../types/form-builder';

interface DroppedItemComponentProps {
    item: DroppedItemInfo;
    level: number;
    onDropOver: (id: number | null) => void;
    onToggleExpand: (id: number) => void;
    onReorderChildren: (parentId: number, children: DroppedItemInfo[]) => void;
    onDeleteItem: (id: number) => void;
}

const DroppedItem: React.FC<DroppedItemComponentProps> = ({
    item,
    level,
    onDropOver,
    onToggleExpand,
    onReorderChildren,
    onDeleteItem,
}) => {
    const [reorderModalOpen, setReorderModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [itemsToDelete, setItemsToDelete] = useState<DroppedItemInfo[]>([]);
    const { setNodeRef, isOver } = useDroppable({
        id: `droppable-${item.id}`,
    });

    const style = {
        position: 'absolute' as const,
        left: `${level * 100}px`,
        top: `${item.verticalPosition}px`,
        width: '200px',
        height: '50px',
        borderRadius: '8px',
        padding: '10px',
        backgroundColor: isOver ? '#00B2A9' : '#006674',
        color: 'white',
        fontWeight: 'bold',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease',
        zIndex: isOver ? 1000 : 1,
    };

    useEffect(() => {
        if (isOver) {
            onDropOver(item.id);
        }
    }, [isOver, item.id, onDropOver]);

    const handleReorderClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setReorderModalOpen(true);
    };

    const handleDeleteClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setItemsToDelete(item.children && item.children.length > 0 ? [item, ...item.children] : [item]);
        setDeleteModalOpen(true);
    };

    const handleConfirmDelete = () => {
        onDeleteItem(item.id);
        setDeleteModalOpen(false);
    };

    return (
        <>
            <div
                ref={setNodeRef}
                style={style}
                onMouseEnter={() => onDropOver(item.id)}
                onMouseLeave={() => onDropOver(null)}
            >
                <span>{item.name}</span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        size="small"
                        onClick={handleDeleteClick}
                        style={{ color: 'red', marginRight: '5px' }}
                    >
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                    {item.children && item.children.length > 1 && (
                        <IconButton
                            size="small"
                            onClick={handleReorderClick}
                            style={{ color: 'white', marginRight: '5px' }}
                        >
                            <SwapVertOutlinedIcon />
                        </IconButton>
                    )}
                    {item.children && item.children.length > 0 && (
                        <IconButton
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                onToggleExpand(item.id);
                            }}
                            style={{ color: 'white' }}
                        >
                            {item.isExpanded ? (
                                <ExpandLessIcon sx={{ fontSize: '40px', strokeWidth: '15px' }} />
                            ) : (
                                <ExpandMoreOutlinedIcon sx={{ fontSize: '40px', strokeWidth: '15px' }} />
                            )}
                        </IconButton>
                    )}
                </div>
            </div>
            {item.isExpanded && item.children && item.children.map((child) => (
                <DroppedItem
                    key={child.id}
                    item={child}
                    level={level + 1}
                    onDropOver={onDropOver}
                    onToggleExpand={onToggleExpand}
                    onReorderChildren={onReorderChildren}
                    onDeleteItem={onDeleteItem}
                />
            ))}
            {item.children && (
                <ReorderModal
                    open={reorderModalOpen}
                    onClose={() => setReorderModalOpen(false)}
                    items={item.children}
                    onReorder={(reorderedItems) => {
                        onReorderChildren(item.id, reorderedItems);
                        setReorderModalOpen(false);
                    }}
                />
            )}
            <DeleteModal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                itemsToDelete={itemsToDelete}
                onConfirmDelete={handleConfirmDelete}
            />
        </>
    );
};

export default DroppedItem;
import { memo, useEffect, useState, useContext } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ReactElement } from 'react';
import { withJsonFormsControlProps, JsonFormsContext } from '@jsonforms/react';
import { GetFromExcel, GetMasters } from '../../services/form-service';
import indexedDBService from '../../services/indexdb-service';

interface IOption {
  label: string;
  value: number | string;
  filterField: string;
  filterValue: string;
}

const SelectControl = memo((props: any): ReactElement => {
  const {
    data,
    label,
    uischema,
    handleChange,
    path,
  } = props;

  const [allApiOptions, setAllApiOptions] = useState<IOption[]>([]);
  const [filteredApiOptions, setFilteredApiOptions] = useState<IOption[]>([]);
  const [dataMapping, setDataMapping] = useState<any>({});
  const [dependsOnOtherFieldName, setDependsOnOtherFieldName] = useState<string>('');
  //const [autoPopulateFields, setAutoPopulateFields] = useState<string[]>([]);
  const formData = useContext(JsonFormsContext).core!.data;
  const [menuItems, setMenuItems] = useState<React.JSX.Element[]>([]);



  useEffect(() => {
    if (formData && dependsOnOtherFieldName && formData[dependsOnOtherFieldName] !== undefined) {
      const newFilteredOptions = allApiOptions.filter(x => x.filterValue === formData[dependsOnOtherFieldName]);
      if (!arraysEqual(filteredApiOptions, newFilteredOptions)) {
        setFilteredApiOptions(newFilteredOptions);
      }

      // this used to be the code i used to clear out other fields that may also be dependent on the dependsOnOtherFieldName
      /*       if (asyncEnum) {
              const { autoPopulateFields } = asyncEnum;
        
              if (autoPopulateFields) {
                console.log('autoPopulateFields', autoPopulateFields)
                autoPopulateFields.forEach(fieldPath => {
                  if (formData) {
                    if (formData[fieldPath] && formData[fieldPath] !== '') {
                      handleChange(fieldPath, '');
                    }
                  }
                });
              }
            } */

    }
  }, [formData?.[dependsOnOtherFieldName]]);

  useEffect(() => {

    if (filteredApiOptions.length === 0) {
      return;
    }

    const menuItemsToReturn = filteredApiOptions.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));
    setMenuItems(menuItemsToReturn);

  }, [filteredApiOptions]);

  const arraysEqual = (a: any[], b: any[]) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  };

  const value = (data: any) => {
    if (uischema.multiple) {
      return data ? data.map((x: { value: any; }) => x?.value) : [];
    } else {
      return data ?? '';
    }
  };

  const [selectedValue, setSelectedValue] = useState<string | string[]>(value(data));

  const createArrayMapping = (options: IOption[]) => {
    const mapping: { [key: string]: IOption } = {};
    options.forEach((option: IOption) => {
      if (option.value !== undefined) {
        mapping[option.value] = option;
      }
    });
    setDataMapping(mapping);
  };

  const getValueByKeyPath = (obj, keyPath) => {
    if (obj && keyPath) {
      return keyPath.split('.').reduce((acc, key) => {
        return acc ? acc[key] : undefined;
      }, obj);
    }
  };

  const uniqueBy = <T, K extends keyof any>(
    list: T[] = [],
    getKey: (item: T) => K,
  ) => {
    return list.reduce((previous, currentItem) => {
      const keyValue = getKey(currentItem)
      const { uniqueMap, result } = previous
      const alreadyHas = uniqueMap[keyValue]
      if (alreadyHas) return previous
      return {
        result: [...result, currentItem],
        uniqueMap: { ...uniqueMap, [keyValue]: true }
      }
    }, { uniqueMap: {} as Record<K, any>, result: [] as T[] }).result
  };

  const mapResponse = (resp: any, valueField: string, textField: string, filterField: string) => {
    let array: any = [];
    if (valueField && textField) {
      array = resp.map((option: IOption) => ({
        label: getValueByKeyPath(option, textField),
        value: getValueByKeyPath(option, valueField),
        filterField: filterField,
        filterValue: getValueByKeyPath(option, filterField)
      }));

      // Sort array alphabetically by label
      array.sort((a: IOption, b: IOption) => a.label.localeCompare(b.label));

    } else {
      array = resp;
    }
    return array;
  };

  const onChange = async (value: any) => {
    if (value === undefined) return;

    if (uischema.multiple) {
      const options: IOption[] = [];
      value.forEach(val => { options.push(dataMapping[val]); });
      handleChange(path, options);
    } else {

      const { asyncEnum } = uischema.options;
      if (asyncEnum) {
        const { valueField, excelUrl, filteredByField, shouldAutoPopulate } = asyncEnum;

        // if i am a field that is filtered from another field
        // then get the value of the field that is filtering me
        // and then lookup any potential field values for my select control
        // that match me and my parent's controlling value
        // then set the field values based on the field(s) found in the lookup
        // except don't set the parent (filtered by field) as it is already set
        if (filteredByField) {
          let filteredByFieldValue;
          if (formData && formData[filteredByField]) {
            filteredByFieldValue = formData[filteredByField]
          }
          if (excelUrl && valueField) {
            const indexStorageData = await indexedDBService.getItem("Master", excelUrl);
            if (indexStorageData) {
              const filteredRecords = indexStorageData.filter(record => record[valueField] === value && record[filteredByField] === filteredByFieldValue);
              if (filteredRecords) {
                filteredRecords.forEach((record) => {
                  for (const key in record) {
                    if (key !== filteredByField) {
                      if (record.hasOwnProperty(key)) {
                        handleChange(key, record[key])
                      }
                    }
                  }
                });
              }
            }
          }
        }
      }

      const option: IOption = dataMapping[value];
      if (option && option.value) {
        handleChange(path, option.value);
      }
    }

    setSelectedValue(value);
  };

  /*
     If menuItems change and there happens to be only 1, auto-select it
     unless its parentField has no value selected. 
  */
  useEffect(() => {
    const { asyncEnum } = uischema.options || {};
    const { doNotAutopopulate, filteredByField } = asyncEnum || {};
    if (menuItems.length === 1 && !doNotAutopopulate) {
      const firstValue = menuItems[0].props.value;
      const parentValue = filteredByField ? formData?.[filteredByField] : true;
      if (parentValue) {
        onChange(firstValue);
      }
    }
  }, [menuItems, formData?.[uischema.options?.asyncEnum?.filteredByField]]);

  const renderSelectedValues = () => {
    if (uischema.multiple) {
      if (selectedValue.length === 1) {
        const selectedItem = filteredApiOptions.find((option) => option.value === selectedValue[0]);
        return selectedItem ? selectedItem.label : '';
      } else {
        return `${selectedValue.length} selected`;
      }
    } else {
      const selectedItem = filteredApiOptions.find((option) => option.value === selectedValue);
      return selectedItem ? selectedItem.label : '';
    }
  };

  useEffect(() => {
    if (allApiOptions.length === 0) {
      const { asyncEnum } = uischema.options;
      if (asyncEnum) {
        const { url, method, valueField, textField, excelUrl, filteredByField } = asyncEnum;  //autoPopulateFields
        if (excelUrl) {
          GetFromExcel({ url, excelUrl })
            .then(data => mapResponse(data, valueField, textField, filteredByField))
            .then(apiOptions => {
              if (filteredByField) {
                setDependsOnOtherFieldName(filteredByField);
              }
              //setAutoPopulateFields(autoPopulateFields);
              setFilteredApiOptions(apiOptions);
              setAllApiOptions(apiOptions);
              createArrayMapping(apiOptions);
            })
            .catch((error) => {
              console.error(`Failed to fetch options data: ${error}`);
            });
        } else {
          GetMasters({ url, method })
            .then(data => mapResponse(data, valueField, textField, ''))
            .then(apiOptions => {
              setFilteredApiOptions(apiOptions);
              setAllApiOptions(apiOptions);
              createArrayMapping(apiOptions);
            })
            .catch((error) => {
              console.error(`Failed to fetch options data: ${error}`);
            });
        }
      } else {
        const options: IOption[] = uniqueBy(uischema.options.enum, (el: any) => el.value);
        if (options) {
          setFilteredApiOptions(options);
          setAllApiOptions(options);
          createArrayMapping(options);
        }
      }
    }
  }, []);

  return (
    <FormControl id={path} variant="standard" fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        value={selectedValue}
        onChange={(event) => onChange(event.target.value)}
        label={label}
        multiple={uischema.multiple}
        renderValue={renderSelectedValues}
      >
        {menuItems}
      </Select>
    </FormControl>
  );
});

export default withJsonFormsControlProps(SelectControl);
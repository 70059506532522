import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FetchFormDataFromToken } from '../services/form-service';
import PublicAddEditFormEntry from '../components/PublicAddEditFormEntry';
import { TokenRequestResponse } from '../types/forms';

const PublicSurveyPage = () => {
    const [surveyId, setSurveyId] = useState<string | null>(null);
    const [formData, setFormData] = useState<TokenRequestResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [isDone, setIsDone] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const surveyIdFromParams = queryParams.get('surveyId');
        if (surveyIdFromParams) {
            setSurveyId(surveyIdFromParams);
        } else {
            setLoading(false);
        }
    }, [location]);

    useEffect(() => {
        const fetchSurveyData = async () => {
            if (surveyId) {
                try {
                    setLoading(true);
                    const response = await FetchFormDataFromToken(surveyId);
                    if (response) {
                        setFormData(response);
                        setShowDialog(true);
                    }
                } catch (error: any) {
                    setErrorMessage(error.message)
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchSurveyData();
    }, [surveyId]);

    const handleSubmitSuccess = () => {
        setShowDialog(false);
        setIsDone(true);
    };

    return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            {isDone ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        backgroundColor: '#f5f5f5',
                    }}
                >
                    <div
                        style={{
                            fontSize: '1.5rem',
                            color: '#4CAF50',
                            backgroundColor: '#e8f5e9',
                            padding: '20px',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            maxWidth: '600px',
                            textAlign: 'center',
                        }}
                    >
                        <p>Survey Submitted Successfully</p>
                        <p style={{ fontSize: '1rem', color: '#000' }}>You may close this page.</p>
                    </div>
                </div>
            ) : (
                <>
                    {loading && <div>Loading...</div>}
                    {!loading && !formData &&
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100vh',
                                backgroundColor: '#f5f5f5',
                            }}
                        >
                            <div
                                style={{
                                    fontSize: '1.5rem',
                                    color: '#f44336',
                                    backgroundColor: '#ffebee',
                                    padding: '20px',
                                    borderRadius: '8px',
                                    minWidth: '40vw',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    maxWidth: '600px',
                                    textAlign: 'center',
                                }}
                            >
                                <p>{errorMessage}</p>
                            </div>
                        </div>
                    }
                    {showDialog && formData && (
                        <PublicAddEditFormEntry
                            showDialog={showDialog}
                            form={formData.form}
                            formData={formData.formData}
                            token={surveyId!}
                            handleClose={() => setShowDialog(false)}
                            handleSubmitSuccess={handleSubmitSuccess}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default PublicSurveyPage;
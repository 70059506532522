import React, { useState, useEffect, useCallback } from 'react';
import { getControlTypes, submitBuildFormRequest } from '../services/form-builder-service';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { BuildFormRequest, ControlTypeInfo, DroppedItemInfo, FormBasicInfo } from '../types/form-builder';
import ControlType from '../components/FormBuilder/ControlType';
import DroppedItem from '../components/FormBuilder/DroppedItem';
import NameInputModal from '../components/FormBuilder/NameInputModal';
import { BinaryEnum, CalculateOperation, Control } from '../types/enums';

const FormBuilderNew: React.FC = () => {
    const [controlTypes, setControlTypes] = useState<ControlTypeInfo[]>([]);
    const [droppedItems, setDroppedItems] = useState<DroppedItemInfo[]>([]);
    const [activeDropTarget, setActiveDropTarget] = useState<number | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentControlType, setCurrentControlType] = useState<Control>(Control.SingleSelect);
    const [wasHoveringOver, setWasHoveringOver] = useState<number | null>(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [draggedItem, setDraggedItem] = useState<DroppedItemInfo | null>(null);

    useEffect(() => {
        const fetchControlTypes = async () => {
            const types = await getControlTypes();
            setControlTypes(types);
        };
        fetchControlTypes();
    }, []);

    const calculateVerticalPositions = useCallback((
        items: DroppedItemInfo[],
        startPosition: number = 20
    ): { updatedItems: DroppedItemInfo[], nextPosition: number } => {
        let currentPosition = startPosition;
        const updatedItems = items.map(item => {
            const updatedItem = { ...item, verticalPosition: currentPosition };
            currentPosition += 70;
            if ((item.children?.length ?? 0) > 0 && item.isExpanded) {
                const { updatedItems: updatedChildren, nextPosition } = calculateVerticalPositions(
                    item.children ?? [],
                    currentPosition
                );
                updatedItem.children = updatedChildren;
                currentPosition = nextPosition;
            }
            return updatedItem;
        });

        return { updatedItems, nextPosition: currentPosition };
    }, []);

    const handleDeleteItem = (id: number) => {
        const deleteItem = (items: DroppedItemInfo[]): DroppedItemInfo[] =>
            items.filter(item => item.id !== id).map(item => ({
                ...item,
                children: item.children ? deleteItem(item.children) : [],
            }));

        setDroppedItems(prevItems => {
            const updatedItems = deleteItem(prevItems);
            return calculateVerticalPositions(updatedItems).updatedItems;
        });
    };

    const handleToggleExpand = (id: number) => {
        const toggleExpansion = (items: DroppedItemInfo[]): DroppedItemInfo[] =>
            items.map(item => ({
                ...item,
                isExpanded: item.id === id ? !item.isExpanded : item.isExpanded,
                children: item.children ? toggleExpansion(item.children) : [],
            }));

        setDroppedItems(prevItems => {
            const updatedItems = toggleExpansion(prevItems);
            return calculateVerticalPositions(updatedItems).updatedItems;
        });
    };

    const handleReorderChildren = (parentId: number, reorderedChildren: DroppedItemInfo[]) => {
        const updateChildrenOrder = (items: DroppedItemInfo[]): DroppedItemInfo[] =>
            items.map(item => ({
                ...item,
                children: item.id === parentId ? reorderedChildren : item.children,
            }));

        setDroppedItems(prevItems => {
            const updatedItems = updateChildrenOrder(prevItems);
            return calculateVerticalPositions(updatedItems).updatedItems;
        });
    };

    const handleDragStart = (event: any) => {
        const { item } = event.active.data.current;
        setDraggedItem(item);
    };

    const handleDragEnd = (event: any) => {
        const { controlType } = event.active.data.current;
        setWasHoveringOver(activeDropTarget);
        setCurrentControlType(controlType.id as Control);
        setIsModalOpen(true);
        setDraggedItem(null);
    };

    const handleNameSubmit = (name: string, enumerations: string[], isRequired: boolean,
        isConditionallyRequired: boolean, conditionallyRequiredBy: number | null, calculateFields: number[],
        calculateOperation: CalculateOperation | null, enumSelect: BinaryEnum | null,
        numeratorField: number | null, dropboxUrl: string | null, leaderId: number | null,
        isEmail: boolean) => {
        const newItem: DroppedItemInfo = {
            id: Date.now(),
            name,
            isExpanded: true,
            enumerations,
            isRequired,
            isConditionallyRequired,
            conditionallyRequiredBy,
            children: [],
            parentId: wasHoveringOver,
            controlType: currentControlType,
            calculateFields,
            calculateOperation: calculateOperation,
            enumSelect: enumSelect,
            numeratorField: numeratorField,
            dropboxUrl: dropboxUrl,
            leaderId: leaderId,
            isEmail: isEmail
        };

        setDroppedItems(prevItems => {
            let updatedItems = wasHoveringOver === null
                ? [...prevItems, newItem]
                : addItemToParent(prevItems, wasHoveringOver, newItem);

            return calculateVerticalPositions(updatedItems).updatedItems;
        });
        setWasHoveringOver(null);
        setActiveDropTarget(null);
    };

    const addItemToParent = (items: DroppedItemInfo[], parentId: number, newItem: DroppedItemInfo): DroppedItemInfo[] =>
        items.map(item => ({
            ...item,
            children: item.id === parentId
                ? [...(item.children || []), newItem]
                : item.children
                    ? addItemToParent(item.children, parentId, newItem)
                    : [],
        }));

    const handleSubmitFormRequest = async (items: DroppedItemInfo[]) => {
        const basicInfo: FormBasicInfo = { name: 'test_name', description: 'test_description' };
        const request: BuildFormRequest = { basicInfo, fields: items };
        try {
            await submitBuildFormRequest(request);
            setSuccessMessage('Success');
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}
            autoScroll={false}>
            <div style={{ display: 'flex', height: '70vh', fontFamily: 'Arial, sans-serif', backgroundColor: '#f8f9fa' }}>
                <div style={{
                    width: '250px',
                    height: '100%',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    padding: '20px',
                    borderRight: '5px solid #ced4da',
                    backgroundColor: '#ffffff',
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                    WebkitOverflowScrolling: 'touch',
                }}>
                    {controlTypes.map((controlType) => (
                        <ControlType key={controlType.id} controlType={controlType} />
                    ))}
                </div>
                <div
                    style={{
                        width: '75%',
                        padding: '20px',
                        position: 'relative',
                        height: '100%',
                        overflowY: 'auto',
                    }}
                    onMouseLeave={() => setActiveDropTarget(null)}
                >
                    {droppedItems.map((item) => (
                        <DroppedItem
                            key={item.id}
                            item={item}
                            level={0}
                            onDropOver={setActiveDropTarget}
                            onToggleExpand={handleToggleExpand}
                            onReorderChildren={handleReorderChildren}
                            onDeleteItem={handleDeleteItem}
                        />
                    ))}
                </div>
            </div>

            <DragOverlay>
                {draggedItem ? (
                    <div>{draggedItem.name}</div>
                ) : null}
            </DragOverlay>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <button
                    onClick={() => handleSubmitFormRequest(droppedItems)}
                    style={{
                        backgroundColor: '#F9C74F',
                        color: 'white',
                        fontWeight: 'bold',
                        border: 'none',
                        borderRadius: '5px',
                        width: '250px',
                        padding: '10px 15px',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s'
                    }}
                >
                    Submit Request
                </button>
            </div>
            {successMessage && <div className="success-message">{successMessage}</div>}
            <NameInputModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onConfirm={handleNameSubmit}
                controlType={currentControlType}
                droppedItems={droppedItems}
                parentId={wasHoveringOver !== null ? wasHoveringOver : undefined}
            />
        </DndContext>
    );
};

export default FormBuilderNew;
import React from 'react';
import { Modal, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DroppedItemInfo } from '../../types/form-builder';

interface DeleteModalProps {
    open: boolean;
    onClose: () => void;
    itemsToDelete: DroppedItemInfo[];
    onConfirmDelete: () => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
    open,
    onClose,
    itemsToDelete,
    onConfirmDelete,
}) => {
    const itemName = itemsToDelete.length > 0 ? itemsToDelete[0].name : '';

    return (
        <Modal open={open} onClose={onClose}>
            <div
                style={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    padding: '20px',
                    height: '220px',
                    width: '400px',
                    margin: 'auto',
                    position: 'relative',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                }}
            >
                <IconButton
                    onClick={onClose}
                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                >
                    <CloseIcon />
                </IconButton>
                <h2 style={{ textAlign: 'center' }}>Deletion Confirmation</h2>
                <div style={{ textAlign: 'center', margin: '20px 0' }}>
                    <p>
                        Are you sure you want to delete <strong>{itemName}</strong>? This will also
                        delete all descendants, if any.
                    </p>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        style={{ backgroundColor: 'red', color: 'white' }}
                        onClick={onConfirmDelete}
                    >
                        Confirm Deletion
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default DeleteModal;
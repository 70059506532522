import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    List,
    ListItem,
    IconButton,
    Checkbox,
    FormControlLabel,
    Select,
    SelectChangeEvent,
    Box,
    MenuItem,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { BinaryEnum, CalculateOperation, Control } from '../../types/enums';
import { DroppedItemInfo } from '../../types/form-builder';

interface NameInputModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: (
        name: string,
        enumerations: string[],
        isRequired: boolean,
        isConditionallyRequired: boolean,
        conditionallyRequiredBy: number | null,
        calculateFields: number[],
        calculateOperation: CalculateOperation | null,
        enumSelect: BinaryEnum | null,
        NumeratorField: number | null,
        dropboxUrl: string | null,
        leaderId: number | null,
        isEmail: boolean,
    ) => void;
    controlType?: number;
    droppedItems: DroppedItemInfo[];
    parentId?: number;
}

const NameInputModal: React.FC<NameInputModalProps> = ({
    open,
    onClose,
    onConfirm,
    controlType,
    droppedItems,
    parentId,
}) => {
    const [name, setName] = useState('');
    const [enumerations, setEnumerations] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState<'NameSelection' | 'EnumerateOptions'>('NameSelection');
    const [isRequired, setIsRequired] = useState(false);
    const [isConditionallyRequired, setIsConditionallyRequired] = useState(false);
    const [conditionallyRequiredBy, setConditionallyRequiredBy] = useState<number | null>(null);
    const [calculateOperation, setCalculateOperation] = useState<CalculateOperation | null>(null);
    const [enumSelect, setEnumSelect] = useState<BinaryEnum | null>(null);
    const [calculateFields, setCalculateFields] = useState<number[]>([]);
    const [NumeratorField, setNumeratorField] = useState<number | null>(null);
    const isEnumerateOptionsAccessible = controlType === Control.SingleSelect || controlType === Control.MultiSelect;
    const isCalculate = controlType === Control.Calculation;
    const nonRequiredtypes = [Control.Calculation, Control.Object, Control.Array];
    const isShowRequiredRadios = !nonRequiredtypes.includes(controlType as Control)
    const [isDropbox, setIsDropbox] = useState(false);
    const [dropboxUrl, setDropboxUrl] = useState<string | null>(null);
    const [leaderId, setLeaderId] = useState<number | null>(null);
    const [isNonSelectFollower, setIsNonSelectFollower] = useState(false);
    const nonSelectAuthPopTypes = [Control.Text]
    const isShowAutoPop = nonSelectAuthPopTypes.includes(controlType as Control);
    const [isEmail, setIsEmail] = useState(false);

    const handleCloseClick = () => {
        resetModal();
        onClose();
    }

    const calculateOperationTypes = Object.entries(CalculateOperation)
        .filter(([key, value]) => typeof value === 'number')
        .map(([key, value]) => ({ id: value as number, name: key }));

    const binaryEnums = Object.entries(BinaryEnum)
        .filter(([key, value]) => typeof value === 'number')
        .map(([key, value]) => ({ id: value as number, name: key }));

    const handleCalculatedFieldChange = (event: SelectChangeEvent<number[]>) => {
        const selectedValues = event.target.value as unknown as number[];
        setCalculateFields((prev) => Array.from(new Set([...prev, ...selectedValues])));
    };

    const getAllDescendants = (items: DroppedItemInfo[], parent: DroppedItemInfo | null): DroppedItemInfo[] => {
        const descendants: DroppedItemInfo[] = [];
        if (!parent) return descendants;
        items.forEach(item => {
            if (item.parentId === parent.id) {
                descendants.push(item);
                descendants.push(...getAllDescendants(items, item));
            }
        });
        return descendants;
    };

    const getFilteredEntries = () => {
        const parentEntry = findParentEntryBFS(droppedItems, parentId ?? null);
        const childrenEntries = parentEntry?.children || [];
        const combinedEntries = parentEntry ? [parentEntry, ...childrenEntries] : childrenEntries;
        const allEntries = [...combinedEntries, ...droppedItems];
        return allEntries.filter((entry, index, self) =>
            index === self.findIndex((e) => e.id === entry.id)
        );
    };

    const findParentEntryBFS = (items: DroppedItemInfo[], parentId: number | null): DroppedItemInfo | null => {
        if (parentId === null) return null;
        const queue: DroppedItemInfo[] = [...items];
        while (queue.length > 0) {
            const currentItem = queue.shift();

            if (currentItem && currentItem.id === parentId) {
                return currentItem;
            }
            if (currentItem) {
                const children = items.filter(item => item.parentId === currentItem.id);
                queue.push(...children);
            }
        }
        return null;
    };

    const handleAddEnumeration = () => {
        setEnumerations([...enumerations, '']);
    };

    const handleEnumerationChange = (index: number, value: string) => {
        const newEnumerations = [...enumerations];
        newEnumerations[index] = value;
        setEnumerations(newEnumerations);
    };

    const handleDeleteEnumeration = (index: number) => {
        const newEnumerations = enumerations.filter((_, i) => i !== index);
        setEnumerations(newEnumerations);
    };

    const handleConfirm = () => {
        if (name.trim()) {
            onConfirm(name, isEnumerateOptionsAccessible ? enumerations : [], isRequired,
                isConditionallyRequired, conditionallyRequiredBy, calculateFields,
                calculateOperation, enumSelect, NumeratorField, dropboxUrl,
                leaderId, isEmail);
            resetModal();
            onClose();
        }
    };

    const resetModal = () => {
        setName('');
        setEnumerations([]);
        setCurrentPage('NameSelection');
        setIsRequired(false);
        setIsConditionallyRequired(false);
        setConditionallyRequiredBy(null);
        setCalculateFields([])
        setCalculateOperation(null)
        setEnumSelect(null)
        setIsDropbox(false)
        setDropboxUrl(null)
        setLeaderId(null)
        setIsNonSelectFollower(false)
        setIsEmail(false)
    };

    const handlePrevious = () => {
        setCurrentPage('NameSelection');
    };

    const handleNext = () => {
        setCurrentPage('EnumerateOptions');
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                {currentPage === 'NameSelection' ? 'Basic Field Information' : 'Enumeration Options'}
                <IconButton style={{ position: 'absolute', right: 10, top: 10 }} onClick={handleCloseClick}>
                    &times;
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {currentPage === 'NameSelection' ? (
                    <>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        {isShowRequiredRadios && (
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isRequired}
                                            onChange={(e) => setIsRequired(e.target.checked)}
                                            color="primary"
                                        />
                                    }
                                    label="Required?"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isConditionallyRequired}
                                            onChange={(e) => setIsConditionallyRequired(e.target.checked)}
                                            color="primary"
                                        />
                                    }
                                    label="Conditionally Required?"
                                />
                                {isShowAutoPop && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isNonSelectFollower}
                                                onChange={(e) => setIsNonSelectFollower(e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label="Auto-Populated?"
                                    />
                                )}
                                {isNonSelectFollower && (
                                    <Select
                                        fullWidth
                                        value={leaderId || ''}
                                        onChange={(e) => setLeaderId(e.target.value as number)}
                                        displayEmpty
                                        variant="outlined"
                                        margin="dense"
                                    >
                                        <MenuItem value="" disabled>Auto-Populated By Whom?</MenuItem>
                                        {getFilteredEntries().map(entry => (
                                            <MenuItem key={entry!.id} value={entry!.id}>{entry!.name}</MenuItem>
                                        ))}
                                    </Select>
                                )}
                                {controlType == Control.Text && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isEmail}
                                                onChange={(e) => setIsEmail(e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label="Email?"
                                    />
                                )}
                            </>
                        )}
                        {isConditionallyRequired && (
                            <Select
                                fullWidth
                                value={conditionallyRequiredBy || ''}
                                onChange={(e) => setConditionallyRequiredBy(e.target.value as number)}
                                displayEmpty
                                variant="outlined"
                                margin="dense"
                            >
                                <MenuItem value="" disabled>Select a conditionally required entry</MenuItem>
                                {getFilteredEntries().map(entry => (
                                    <MenuItem key={entry!.id} value={entry!.id}>{entry!.name}</MenuItem>
                                ))}
                            </Select>
                        )}
                        {isCalculate && (
                            <>
                                <div style={{
                                    paddingTop: '10px',
                                    paddingBottom: '10px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}>
                                    <h2 style={{
                                        fontSize: '15px',
                                        color: '#000',
                                        margin: 0,
                                    }}>
                                        Calculation Parameters
                                    </h2>
                                </div>
                                <InputLabel id="calculate-operation-label">Operation Type</InputLabel>
                                <Select
                                    fullWidth
                                    value={calculateOperation}
                                    onChange={(e) => setCalculateOperation(e.target.value as CalculateOperation)}
                                    displayEmpty
                                    variant="outlined"
                                    margin="dense"
                                >
                                    <MenuItem value="" disabled>
                                        Select an Operation Type
                                    </MenuItem>
                                    {calculateOperationTypes.map((operation) => (
                                        <MenuItem key={operation.id} value={operation.id}>
                                            {operation.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <div style={{ height: '20px' }}></div>
                                <InputLabel id="calculate-fields-label">
                                    {calculateOperation != CalculateOperation.Divide ? "Pertinent Field(s)" : "Denominator(s)"}
                                </InputLabel>
                                <Select
                                    multiple
                                    fullWidth
                                    value={calculateFields}
                                    onChange={handleCalculatedFieldChange}
                                    displayEmpty
                                    variant="outlined"
                                    margin="dense"
                                >
                                    <MenuItem value="" disabled>
                                        {calculateOperation != CalculateOperation.Divide
                                            ? "Select the Entries Involved in the Calculation"
                                            : "Select the Denominator(s)"}
                                    </MenuItem>
                                    {getFilteredEntries().map((entry) => (
                                        <MenuItem key={entry.id} value={entry.id}>
                                            {entry.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {isCalculate && calculateOperation === CalculateOperation.Count && (
                                    <>
                                        <div style={{ height: '20px' }}></div>
                                        <InputLabel id="enum-value-label">Countable Value</InputLabel>
                                        <Select
                                            fullWidth
                                            value={enumSelect}
                                            onChange={(e) => setEnumSelect(e.target.value as BinaryEnum)}
                                            displayEmpty
                                            variant="outlined"
                                            margin="dense"
                                        >
                                            <MenuItem value="" disabled>
                                                Select the Value to Count
                                            </MenuItem>
                                            {binaryEnums.map((operation) => (
                                                <MenuItem key={operation.id} value={operation.id}>
                                                    {operation.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </>)}
                                {calculateOperation == CalculateOperation.Divide && (
                                    <>
                                        <InputLabel id="Numerator-label">Numerator Field</InputLabel>
                                        <Select
                                            fullWidth
                                            value={NumeratorField}
                                            onChange={(e) => setNumeratorField(e.target.value as number)}
                                            displayEmpty
                                            variant="outlined"
                                            margin="dense"
                                        >
                                            <MenuItem value="" disabled>
                                                Select the Numerator Field
                                            </MenuItem>
                                            {getFilteredEntries().map((entry) => (
                                                <MenuItem key={entry.id} value={entry.id}>
                                                    {entry.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    true && (
                        <>
                            <Box display="flex" flexDirection="column" alignItems="flex-start">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isDropbox}
                                            onChange={(e) => setIsDropbox(e.target.checked)}
                                            color="primary"
                                        />
                                    }
                                    label="Integrate With Dropbox?"
                                />
                                {isDropbox && (
                                    <TextField
                                        label="Dropbox URL"
                                        value={dropboxUrl || ''}
                                        onChange={(e) => setDropboxUrl(e.target.value)}
                                        fullWidth
                                        style={{ marginTop: '10px' }}
                                    />
                                )}
                                {isDropbox && (
                                    <Select
                                        fullWidth
                                        value={leaderId || ''}
                                        onChange={(e) => setLeaderId(e.target.value as number)}
                                        displayEmpty
                                        variant="outlined"
                                        margin="dense"
                                    >
                                        <MenuItem value="" disabled>Determined By</MenuItem>
                                        {getFilteredEntries().map(entry => (
                                            <MenuItem key={entry!.id} value={entry!.id}>{entry!.name}</MenuItem>
                                        ))}
                                    </Select>
                                )}
                                {!isDropbox && (
                                    <Button
                                        onClick={handleAddEnumeration}
                                        startIcon={<AddIcon />}
                                        style={{ marginTop: '10px' }}
                                    >
                                        Add Enumeration
                                    </Button>
                                )}
                            </Box>
                            <List
                                hidden={isDropbox}
                            >
                                {enumerations.map((enumItem, index) => (
                                    <ListItem key={index}>
                                        <TextField
                                            value={enumItem}
                                            onChange={(e) => handleEnumerationChange(index, e.target.value)}
                                            fullWidth
                                            variant="outlined"
                                            placeholder={`Enumeration ${index + 1}`}
                                        />
                                        <IconButton onClick={() => handleDeleteEnumeration(index)} edge="end"
                                            aria-label="delete">
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    )
                )}
            </DialogContent>
            <DialogActions>
                {currentPage === 'EnumerateOptions' && (
                    <Button onClick={handlePrevious} color="primary">
                        Previous
                    </Button>
                )}
                <Button
                    onClick={currentPage === 'NameSelection' && isEnumerateOptionsAccessible ?
                        handleNext : handleConfirm}
                    color="primary"
                    disabled={currentPage === 'NameSelection' && !name.trim()}
                >
                    {currentPage === 'NameSelection' && isEnumerateOptionsAccessible ? 'Next' : 'Confirm'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NameInputModal;
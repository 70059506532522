import ShoFormsClient from '../httpClient/ShoFormsClient';
import { BuildFormRequest, ControlTypeInfo } from '../types/form-builder';

const apiPathPrefix = '/form-builder';

export const getControlTypes = async (): Promise<ControlTypeInfo[]> => {
    try {
        const instance = ShoFormsClient();
        const response = await instance.get(apiPathPrefix + '/list-control-types');
        return response.data as ControlTypeInfo[];
    } catch (error) {
        throw error;
    }
};

export const submitBuildFormRequest = async (request: BuildFormRequest) => {
    try {
        const instance = ShoFormsClient();
        return await instance.post(apiPathPrefix + '/build-form', request);
    } catch (error) {
        throw error;
    }
};